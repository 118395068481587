<template>
    <svg width="18px" height="17px" viewBox="0 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-748.000000, -643.000000)" fill="#000000" fill-rule="nonzero">
                <g transform="translate(210.000000, 264.000000)">
                    <g transform="translate(516.000000, 366.000000)">
                        <g>
                            <g transform="translate(22.000000, 13.000000)">
                                <path d="M12.5555556,0.844444444 C12.8869264,0.844444444 13.1555556,1.11307359 13.1555556,1.44444444 L13.1555556,1.44444444 L13.1555556,15.6666667 C13.1555556,15.9980375 12.8869264,16.2666667 12.5555556,16.2666667 L12.5555556,16.2666667 L1,16.2666667 C0.66862915,16.2666667 0.4,15.9980375 0.4,15.6666667 L0.4,15.6666667 L0.4,1.44444444 C0.4,1.11307359 0.66862915,0.844444444 1,0.844444444 L1,0.844444444 Z M17,0.844444444 C17.3313708,0.844444444 17.6,1.11307359 17.6,1.44444444 C17.6,1.74267821 17.3824104,1.9900912 17.0973231,2.03659146 L17,2.04444444 L16.711,2.04444444 L16.711,15.0664444 L17,15.0666667 C17.3313708,15.0666667 17.6,15.3352958 17.6,15.6666667 C17.6,15.9649004 17.3824104,16.2123134 17.0973231,16.2588137 L17,16.2666667 L15.2222222,16.2666667 C14.8908514,16.2666667 14.6222222,15.9980375 14.6222222,15.6666667 C14.6222222,15.3684329 14.8398118,15.1210199 15.1248991,15.0745197 L15.2222222,15.0666667 L15.511,15.0664444 L15.511,2.04444444 L15.2222222,2.04444444 C14.8908514,2.04444444 14.6222222,1.77581529 14.6222222,1.44444444 C14.6222222,1.14621068 14.8398118,0.898797691 15.1248991,0.852297431 L15.2222222,0.844444444 L17,0.844444444 Z M1.599,10.0994444 L1.6,15.066 L11.955,15.066 L11.954,10.0994444 L1.599,10.0994444 Z M5.80808179,2.04308657 L1.6,2.044 L1.599,8.89944444 L5.733,8.89944444 L5.73333333,2.33333333 C5.73333333,2.22804968 5.76045063,2.12909962 5.80808179,2.04308657 Z M11.955,2.044 L6.85858488,2.04308657 C6.89122456,2.10202778 6.91423134,2.16704396 6.92548035,2.23601022 L6.93333333,2.33333333 L6.933,8.89944444 L11.954,8.89944444 L11.955,2.044 Z" id="Combined-Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
  name: 'IconStructure'
}
</script>

